
import moment from 'moment';
import { deletePost } from '@/api/post';
import { UserModule } from '@/store/user';
import { IBoard, IPost, IPostFile } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Kakao from '@/assets/js/kakao.min.js';
import ApplyForm from '@/components/apply/form.vue';
import { deletePostApply, getOwnPostApply } from '@/api/post-apply';
import { getUserInfo } from '@/api/user';
import ApplyList from '@/components/apply/index.vue';

@Component({
  name: 'BoardSupportDetail',
  components: {
    ApplyList,
    ApplyForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) public post!: IPost

  mounted() {
    if (!Kakao.isInitialized()) Kakao.init(process.env.VUE_APP_KAKAO_API_KEY);
    if (this.isLogined) {
      this.getPostApply();
    }
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get isLogined() {
    return UserModule.token !== '';
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private applyVisible = false;

  private postApply: any = null;

  private handleClickDelete() {
    if (window.confirm('정말 게시글을 삭제하시겠습니까?')) {
      deletePost(this.$route.params.postUid).then(() => {
        alert('게시글이 삭제되었습니다.');
        this.$router.push({ name: 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
      });
    }
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private isEnd() {
    if (!this.post.dataList[3].inputValue || !this.post.dataList[4].inputValue) return false;
    const today = moment();
    const startDay = moment(this.post.dataList[3].inputValue, 'YYYY-MM-DD');
    const endDay = moment(this.post.dataList[4].inputValue, 'YYYY-MM-DD').hours(23).minutes(59).seconds(59);
    return today.isSameOrAfter(startDay) && today.isSameOrBefore(endDay);
  }

  private filterContents(contents: string | null) {
    if (contents) return contents.replace(/(<([^>]+)>)/ig, '');
    return '';
  }

  private handleClickShare(sns: string) {
    /* eslint-disable */
    switch (sns) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://www.twitter.com/intent/tweet?url=${window.location.href}`, '_blank');
        break;
      case 'google':
        window.open(`https://www.plus.google.com/share?url=${window.location.href}`, '_blank');
        break;
      case 'kakao':
        const location = window.location.href;
        const contents = this.filterContents(this.post.dataList[1].inputValue);
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: this.post.dataList[0].inputValue || '경남창업포털사인',   // 콘텐츠의 타이틀
            description: contents,   // 콘텐츠 상세설명
            imageUrl: `${window.location.origin}/ft_logo.png`,
            link : {
              mobileWebUrl: location,   // 모바일 카카오톡에서 사용하는 웹 링크 URL
              webUrl: location, // PC버전 카카오톡에서 사용하는 웹 링크 URL
            },
          },
        });
        break;
      default:
        break;
    }
    /* eslint-enable */
  }

  private handleClickApply() {
    (this.$refs.applyForm as any).handleSubmitForm();
  }

  private handleSuccessApply() {
    this.$router.go(0);
  }

  /* eslint-disable */
  private getPostApply() {
    const listQuery = {
      page: 0,
      size: 10,
      postUid: this.post.uid,
    };
    getOwnPostApply(listQuery).then((res) => {
      this.applyVisible = true;
      this.postApply = res.data.content[0];
    });
  }
  /* eslint-enable */

  private handleDeleteApply() {
    if (window.confirm('정말 지원 신청을 취소하시겠습니까?')) {
      deletePostApply(this.postApply.uid).then(() => {
        this.$router.go(0);
      });
    }
  }
}
