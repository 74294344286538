
import { deletePost } from '@/api/post';
import { UserModule } from '@/store/user';
import { IBoard, IPost, IPostFile } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardPhotoDetail',
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) private post!: IPost

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private handleClickDelete() {
    if (window.confirm('정말 게시글을 삭제하시겠습니까?')) {
      deletePost(this.$route.params.postUid).then(() => {
        alert('게시글이 삭제되었습니다.');
        this.$router.push({ name: 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
      });
    }
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private getThumnail(inputValue: string) {
    if (inputValue.startsWith('http')) return inputValue;
    return `${this.fileUrl}/${inputValue}`;
  }
}
