
import { checkPostPassword, deletePost, deleteSecretPost } from '@/api/post';
import { UserModule } from '@/store/user';
import { IBoard, IPost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardDefaultDetail',
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) private post!: IPost

  @Prop({ required: true }) private boardSkin!: string

  private passwordCheckVisible = false;

  private userRole = UserModule.roles.indexOf('ROLE_ADMIN') > -1;

  private mode = '';

  private password = '';

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  mounted() {
    console.log(UserModule.roles.indexOf('ROLE_ADMIN'));
  }

  private handleClickDelete() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'delete';
      return;
    }
    if (window.confirm('정말 게시글을 삭제하시겠습니까?')) {
      deletePost(this.$route.params.postUid).then(() => {
        window.alert('게시글이 삭제되었습니다.');
        this.$router.push({ name: this.$route.name === 'AppPostDetail' ? 'AppBoard' : 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
      });
    }
  }

  private handleClickDeleteByPassword() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        switch (this.mode) {
          case 'delete':
            if (window.confirm('정말 게시글을 삭제하시겠습니까?')) {
              deleteSecretPost(this.$route.params.postUid, this.password).then(() => {
                window.alert('게시글이 삭제되었습니다.');
                this.$router.push({ name: 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
              }).catch(() => {
                alert('게시글 패스워드가 일치하지 않습니다.');
              });
            }
            break;
          case 'update':
            checkPostPassword(this.$route.params.postUid, this.password).then((res) => {
              const isMatch = res.data;
              if (isMatch) {
                this.$router.push({
                  name: 'PostUpdate',
                  params: {
                    menuUid: this.$route.params.menuUid,
                    postUid: this.$route.params.postUid,
                  },
                  query: {
                    password: this.password,
                  },
                });
              } else {
                alert('게시글 패스워드가 일치하지 않습니다.');
              }
            });
            break;
          default:
            break;
        }
      }
    });
  }

  private handleClickUpdate() {
    if (this.board.authWrite === 'GUEST' && !this.isAdmin) {
      this.passwordCheckVisible = true;
      this.mode = 'update';
    } else {
      this.$router.push({
        name: this.$route.name === 'AppPostDetail' ? 'AppPostUpdate' : 'PostUpdate',
        params: {
          menuUid: this.$route.params.menuUid,
          postUid: this.$route.params.postUid,
        },
      });
    }
  }

  private handleClickCancle() {
    this.passwordCheckVisible = false;
    this.mode = '';
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }
}
