
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { getPostView } from '@/api/post';
import { getMenu } from '@/api/menu';
import BoardBasicDetail from '@/components/board/basic/detail.vue';
import BoardDefaultDetail from '@/components/board/default/detail.vue';
import BoardInterviewDetail from '@/components/board/interview/detail.vue';
import BoardSupportDetail from '@/components/board/support/detail.vue';
import BoardEventDetail from '@/components/board/event/detail.vue';
import BoardMentoDetail from '@/components/board/mento/detail.vue';
import BoardYoutubeDetail from '@/components/board/youtube/detail.vue';
import BoardGuideDetail from '@/components/board/guide/detail.vue';
import { IBoard, IMenuDetail, IPost } from '@/types';
import { getBoard } from '@/api/board';

@Component({
  name: 'BoardDetail',
  components: {
    BoardDefaultDetail,
    BoardInterviewDetail,
    BoardSupportDetail,
    BoardEventDetail,
    BoardMentoDetail,
    BoardYoutubeDetail,
    BoardGuideDetail,
    BoardBasicDetail,
  },
})
export default class extends Vue {
  mounted() {
    const load = this.$loading.show();
    getMenu(this.$route.params.menuUid).then((res) => {
      getBoard(res.data.board.uid).then((response) => {
        this.board = response.data;
        load.hide();
      });
    });
    getPostView(this.$route.params.postUid).then((res: any) => {
      this.post = res.data;
    });
  }

  private board: IBoard | null = null;

  private post: IPost | null = null;
}
